import React from "react"
//import loadable from '@loadable/component'
import Layout from "../components/Layout"
import { Container, Paper } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "64px",
  },
  outerIframe: {
    maxWidth: 800,
    maxHeight: 2950,
    overflow: "hidden",
    marginLeft: "auto ",
    marginRight: "auto ",
  },
  innerIframe: {
    position: "relative",
    height: 0,
    "-webkit-overflow-scrolling": "touch",
    overflow: "auto",
    paddingBottom: "370%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    margin: 0,
    padding: 0,
    border: "none",
  },
}))

export default function Inscripcion(props) {
  const classes = useStyles()

  return (
    <Layout>
      <Container className={classes.root} maxWidth="lg">
        <Paper style={{ background: "#f7b032" }}>
          <div className={classes.outerIframe}>
            <div className={classes.innerIframe}>
              <iframe
                title="Formulario de inscripcion"
                className={classes.iframe}
                src="https://docs.google.com/forms/d/e/1FAIpQLSdY1R8UYX6zIj5bxE7Ug7s123RuYDrFUfIFjSK4My7clQnZOA/viewform?embedded=true"
                width="640"
                height="2729"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
          </div>
        </Paper>
      </Container>
    </Layout>
  )
}


